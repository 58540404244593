/**
 * Returns an object with the unique keywords existing on a given string and the errors associated.
 * @param textInput String with keywords separated by commas.
 * @param currentKeywords Current array of existing keywords.
 * @param minLength Minimum number of characters that a keyword must have.
 * @returns An object with the array of unique keywords and a string error message.
 */
export const getUniqueKeywordsAndError = (
  textInput: string,
  currentKeywords: string[],
  minLength = 2
) => {
  const duplicatedKeywords: string[] = [];

  // Remove duplicates and empty keywords
  const newKeywords = new Set(
    textInput
      .trim()
      .split(',')
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword !== '' && keyword.length >= minLength)
  );

  // Check if each keyword already exists and remove it from uniqueKeywords if necessary
  newKeywords.forEach((keyword) => {
    if (currentKeywords.includes(keyword)) {
      duplicatedKeywords.push(`"${keyword}"`);
      newKeywords.delete(keyword);
    }
  });

  return {
    keywordsArray: [...currentKeywords, ...newKeywords],
    keywordsError:
      duplicatedKeywords.length > 0
        ? `${duplicatedKeywords.join(', ')} already exists`
        : null
  };
};

/**
 * Returns a list of unique keyword options sorted by type.
 * @param allKeywords Array of keyword options to deduplicate and sort.
 * @returns Sorted array of unique keyword options.
 */
export const getUniqueFilterOptions = (
  allKeywords: Array<{ type: string; title: string; id: string }>
) => {
  // Use a Map to keep track of unique ids or titles
  const uniqueEntries = new Map();

  const uniqueOptions = allKeywords.filter((option) => {
    const key = option.type === 'Keyword' ? option.title : option.id;
    if (!uniqueEntries.has(key)) {
      uniqueEntries.set(key, true);
      return true;
    }
    return false;
  });

  return uniqueOptions.sort((a, b) => a.type.localeCompare(b.type));
};
