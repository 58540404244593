import { useParams } from 'react-router';

import { Typography } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { deleteKeywords } from '@/api/keywords';
import Button from '@/components/button';
import Dialog from '@/components/dialog';
import useAlert from '@/hooks/useAlert';

const DeleteKeywordsDialog = ({
  isOpen,
  onClose,
  selectedKeywords,
  keywordsList
}) => {
  const queryClient = useQueryClient();
  const id = Number(useParams().id);
  const alert = useAlert();

  const handleConfirmDeleteKeyword = () => {
    const keywordsToDelete = keywordsList
      .filter((keyword) => selectedKeywords.includes(keyword.id))
      .map((keyword) => keyword.value);

    const data = {
      values: keywordsToDelete.join(','),
      categoryId: id
    };

    deleteKeywords(data)
      .then(() => {
        const alertMessage =
          selectedKeywords.length === 1
            ? `"${keywordsToDelete[0]}" has been successfully deleted`
            : `${selectedKeywords.length} keywords have been successfully deleted`;

        alert.info(alertMessage);
        queryClient.invalidateQueries({
          queryKey: ['keywordGroups'],
          refetchType: 'all'
        });
      })
      .catch(() =>
        alert.error(`Some error occurred while deleting the keywords`)
      )
      .finally(() => {
        onClose();
      });
  };

  return (
    <Dialog
      title="Delete Keyword"
      dialogContent={
        selectedKeywords.length === 1 ? (
          <>
            <Typography>
              Are you sure you want to delete &ldquo;
              {
                keywordsList.find((item) => item.id === selectedKeywords[0])
                  ?.value
              }
              &ldquo;?
            </Typography>
            <Typography>
              You will not be able to recover this keyword.
            </Typography>
          </>
        ) : (
          <>
            <Typography>
              Are you sure you want to delete {selectedKeywords.length}{' '}
              keywords?
            </Typography>
            <Typography>You will not be able to recover these.</Typography>
          </>
        )
      }
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} variant="text" text="cancel" />
          <Button
            onClick={handleConfirmDeleteKeyword}
            color="error"
            text="delete"
          />
        </>
      }
    />
  );
};

DeleteKeywordsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedKeywords: PropTypes.array.isRequired,
  keywordsList: PropTypes.array.isRequired
};

export default DeleteKeywordsDialog;
