import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { List as ListIcon, Search } from '@mui/icons-material';
import {
  Checkbox,
  Chip,
  TextField,
  Typography,
  ListItem,
  ListItemText,
  List,
  Stack,
  Badge,
  Popover
} from '@mui/material';

import PropTypes from 'prop-types';

import AutocompleteComponent from '@/components/autocomplete';
import Button from '@/components/button/Button';
import Dialog from '@/components/dialog';
import { DEFAULT_PAGE } from '@/constants/table';
import { useUrlParams } from '@/hooks/urlParams';
import useMenuAnchor from '@/hooks/useMenuAnchor';
import { convertParametersToForm } from '@/utils/evaluations';
import getRoute from '@/utils/getRoute/getRoute';
import { getUniqueFilterOptions } from '@/utils/keywords';

const KeywordsFilter = ({ parameters }) => {
  const evaluationId = Number(useParams().evaluationId);

  const urlParams = useUrlParams(getRoute.evaluations.DETAIL(evaluationId), {
    keywords: undefined,
    keywordGroups: undefined
  });

  const {
    mightKeywordsAndKeywordsGroup,
    mustKeywordsAndKeywordsGroup,
    noneKeywordsAndKeywordsGroup,
    someKeywordsAndKeywordsGroup
  } = convertParametersToForm(parameters);

  const filterOptions = useMemo(
    () =>
      getUniqueFilterOptions([
        ...mightKeywordsAndKeywordsGroup.groups,
        ...mightKeywordsAndKeywordsGroup.keywords,
        ...mustKeywordsAndKeywordsGroup.groups,
        ...mustKeywordsAndKeywordsGroup.keywords,
        ...noneKeywordsAndKeywordsGroup.groups,
        ...noneKeywordsAndKeywordsGroup.keywords,
        ...someKeywordsAndKeywordsGroup.groups,
        ...someKeywordsAndKeywordsGroup.keywords
      ]),
    [
      mightKeywordsAndKeywordsGroup.groups,
      mightKeywordsAndKeywordsGroup.keywords,
      mustKeywordsAndKeywordsGroup.groups,
      mustKeywordsAndKeywordsGroup.keywords,
      noneKeywordsAndKeywordsGroup.groups,
      noneKeywordsAndKeywordsGroup.keywords,
      someKeywordsAndKeywordsGroup.groups,
      someKeywordsAndKeywordsGroup.keywords
    ]
  );

  const {
    anchorEl: anchorElPopover,
    handleOpen: handleOpenPopover,
    handleClose: handleClosePopover,
    isOpen: isPopoverOpen
  } = useMenuAnchor();

  const [keywordGroupDialog, setKeywordGroupDialog] = useState({
    group: {},
    isOpen: false
  });

  const handleClickOpenKeywordGroup = (group) => {
    setKeywordGroupDialog({ group, isOpen: true });
  };

  const handleCloseKeywordGroup = () => {
    setKeywordGroupDialog({ group: {}, isOpen: false });
  };

  const handleOnChange = (event, newValue, reason, details) => {
    if (reason === 'clear') {
      urlParams.updateParams({
        keywordGroups: undefined,
        keywords: undefined
      });
      return;
    }
    const type =
      details.option.type === 'Keyword' ? 'keywords' : 'keywordGroups';

    const oldValue =
      urlParams.params[type] !== undefined
        ? urlParams.params[type].split(',')
        : [];

    const newItem =
      type === 'keywords' ? details.option.title : details.option.id;
    switch (reason) {
      case 'selectOption':
        urlParams.updateParams({
          ...urlParams.params,
          [type]: [...oldValue, newItem].join(','),
          page: DEFAULT_PAGE
        });
        break;
      case 'removeOption':
        {
          const index = oldValue.findIndex((item) => item === newItem);

          oldValue.splice(index, 1);

          urlParams.updateParams({
            ...urlParams.params,
            [type]: oldValue.join(','),
            page: DEFAULT_PAGE
          });
        }
        break;
      default:
    }
  };

  const filterValue = useMemo(() => {
    const selectedGroups =
      urlParams.params?.keywordGroups
        ?.split(',')
        .map((group) => Number(group.trim())) || [];
    const selectedKeywords =
      urlParams.params?.keywords?.split(',').map((keyword) => keyword.trim()) ||
      [];

    return {
      groups: filterOptions.filter((keyword) =>
        selectedGroups.includes(keyword.id)
      ),
      keywords: filterOptions.filter((keyword) =>
        selectedKeywords.includes(keyword.title)
      )
    };
  }, [urlParams.params, filterOptions]);

  const isBadgeVisible =
    filterValue?.groups?.concat(filterValue?.keywords).length > 0;

  const popoverId = isPopoverOpen ? 'keywords-popover' : undefined;

  return (
    <>
      <Stack alignItems="center" justifyContent="center">
        <Button
          text="Keywords"
          variant="text"
          onClick={handleOpenPopover}
          startIcon={
            <Badge
              color="primary"
              invisible={!isBadgeVisible}
              badgeContent={Object.values(filterValue).flat().length}
            >
              <Search fontSize="small" />
            </Badge>
          }
          size="small"
        />
      </Stack>
      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={anchorElPopover}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack gap={1} padding={2} width="450px">
          <Typography variant="body2">
            Search for keywords or keywords groups
          </Typography>
          <AutocompleteComponent
            size="small"
            value={[...filterValue.groups, ...filterValue.keywords]}
            options={filterOptions}
            groupBy={(option) => option.type}
            onChange={handleOnChange}
            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={(option, value) =>
              option.type === value.type && option.title === value.title
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                label="Value"
                placeholder="Filter value"
              />
            )}
            renderOption={(props, option, { selected }) => (
              <Stack direction="row" alignItems="center" {...props}>
                <Checkbox checked={selected} color="primary" />
                <Typography>{option.title}</Typography>
                {option.type === 'Keyword group' && (
                  <Typography color="textSecondary" ml="auto" variant="body2">
                    {option.keywords.length}
                  </Typography>
                )}
              </Stack>
            )}
            renderTags={(tags, getTagProps) =>
              tags.map((tag, index) => {
                const color = tag.type === 'Keyword' ? 'opacity75' : 'primary';

                const onClick =
                  tag.type !== 'Keyword'
                    ? () => handleClickOpenKeywordGroup(tag)
                    : null;

                return (
                  <Chip
                    {...getTagProps({ index })}
                    color={color}
                    icon={
                      tag.type !== 'Keyword' ? (
                        <ListIcon style={{ color: '#FFF' }} />
                      ) : undefined
                    }
                    key={`chip__${tag.title}`}
                    label={tag.title}
                    onClick={onClick}
                    size="small"
                    style={{
                      borderColor: 'inherit'
                    }}
                    variant="filled"
                  />
                );
              })
            }
          />
          <Dialog
            maxWidth="lg"
            open={keywordGroupDialog.isOpen}
            onClose={handleCloseKeywordGroup}
            title={keywordGroupDialog.group.title}
            dialogContent={
              <List
                style={{ position: 'relative', maxHeight: 300 }}
                subheader={<li />}
              >
                {keywordGroupDialog.group?.keywords?.map((keyword) => (
                  <ListItem key={`item-${keyword}}`}>
                    <ListItemText primary={keyword} />
                  </ListItem>
                ))}
              </List>
            }
          />
        </Stack>
      </Popover>
    </>
  );
};

KeywordsFilter.propTypes = {
  parameters: PropTypes.object.isRequired,
  filterExpand: PropTypes.bool,
  filterExpandColor: PropTypes.bool,
  handleExpand: PropTypes.func,
  onClickAway: PropTypes.func
};

export default KeywordsFilter;
