import { useState, ReactNode } from 'react';

import {
  Box,
  Typography,
  Stack,
  Divider,
  useTheme,
  Card,
  Popover
} from '@mui/material';

interface DashboardCardProps {
  icon: ReactNode;
  title: string;
  value: string | number | undefined;
  descriptionPercentage: number;
  description: string;
  items?: Array<{
    icon: ReactNode;
    text: string;
    value: string | number | undefined;
  }>;
  dataTestId?: string;
}

const DashboardCard = ({
  icon,
  title,
  value,
  description,
  items,
  descriptionPercentage,
  dataTestId = 'dashboard-card-default'
}: DashboardCardProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // Popover state
  const [popoverWidth, setPopoverWidth] = useState<number | undefined>(
    undefined
  ); // Store popover width
  const theme = useTheme();

  const isClickable = value !== 0; // Determine if the card should be clickable
  const haveItems = items && items.length > 0;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isClickable && haveItems) {
      // Only allow click if value is not 0
      setAnchorEl(event.currentTarget); // Set anchor element for the Popover
      setPopoverWidth(event.currentTarget.clientWidth); // Capture the width when opening
    }
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the Popover
  };

  const open = Boolean(anchorEl);
  const id = open ? 'dashboard-card-popover' : undefined;

  return (
    <Box
      minWidth="314px"
      position="relative"
      flexGrow={1}
      flexBasis={0}
      data-testid={dataTestId}
    >
      <Box
        position="absolute"
        top="-18px"
        left="22px"
        bgcolor={theme.palette.primary.main}
        p={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={1}
        sx={{
          opacity: isClickable ? 1 : 0.5
        }}
      >
        {icon}
      </Box>
      <Box
        position="relative"
        marginTop="16px"
        data-testid="dashboard-card-toggle"
        onClick={handleClick} // Open popover on click
        sx={{
          cursor: isClickable && haveItems ? 'pointer' : 'default', // Disable pointer cursor if not clickable
          opacity: isClickable ? 1 : 0.5, // Grey out when not clickable
          '&:hover':
            isClickable && haveItems
              ? {
                  outline: `2px solid ${theme.palette.primary.main}`
                }
              : {}
        }}
      >
        <Card
          sx={{
            pt: '12px',
            px: '20px',
            pb: '19px',
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.15)'
          }}
        >
          <Stack gap={2}>
            <Stack>
              <Typography variant="body2" color="textSecondary" align="right">
                {title}
              </Typography>
              <Typography variant="h5" align="right" fontWeight={700}>
                {value}
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" gap={0.8}>
              <Typography variant="body2" fontWeight={700}>
                {descriptionPercentage}%
              </Typography>
              <Typography
                variant="body2"
                noWrap
                sx={{
                  color: 'var(--Light-Surfaces-Text-Surface-Subtle, #757575)'
                }}
              >
                {description}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Box>

      {/* Popover component */}
      <Popover
        elevation={2}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        slotProps={{
          paper: {
            sx: {
              width: popoverWidth // Use stored width
            }
          }
        }}
      >
        <Box p={2} bgcolor="background.paper" boxShadow={1}>
          <Stack gap={1}>
            {items?.map((item, index) => (
              <Stack
                key={index}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={1}>
                  {item.icon}
                  <Typography>{item.text}</Typography>
                </Box>
                <Typography
                  sx={{
                    color: 'var(--Light-Surfaces-Text-Surface-Subtle, #757575)'
                  }}
                >
                  {item.value}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
};

export default DashboardCard;
